<template>
  <div>
    <MyCommuLoading v-if="loading"/>
    <div v-if="!loading">
      <div
        v-if="hasSchedule"
        class="scroll-container"
      >
        <div v-for="event in recents" :key="event.id">
          <MyCommuCardEvent v-if="mayOut(event)" :path="event.organizationPath" :event="event"/>
        </div>
      </div>
      <div v-if="!hasSchedule" class="title-schedule">交流イベント予約や
        <br>
        シェアリング成立で
        <br>
        「予定」が表示されます</div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('home', ['recents', 'page']),
    ...mapState('mycommu', ['loading']),
    hasSchedule () {
      let count = 0
      this.recents.forEach((event) => {
        if (this.mayOut(event)) {
          count += 1
        }
      })
      return count !== 0
    }
  },
  methods: {
    mayOut (event) {
      return event.user_request_status !== 'no_joined' && event.status !== 'finished' &&
          (event.sharingType === 'events' || event.sharingType === 'events_in_group')
    }
  }
}
</script>
<style scoped lang="sass">
  .title-schedule
    color: #2DBA87
    font-size: 20px
    font-weight: bold
    padding: 16px
    text-align: center

</style>
