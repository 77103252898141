<template>
  <div
    class="my-commu-domain group-menu-bar"
  >
    <ul>
      <li class="item" :class="active('all')" @click="activeType('all')">
        <a href="javascript:void(0)">
          <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10508_10931)">
              <path d="M9.14794 25.7626L9.14805 25.7626L9.14062 25.7533L3.51562 18.7133L2.625 17.5986V19.0254V25.0976C2.625 25.6795 2.14619 26.1585 1.5625 26.1585C0.978807 26.1585 0.5 25.6795 0.5 25.0976V14.561C0.5 14.1196 0.78593 13.7146 1.21191 13.5656C1.63755 13.4168 2.11596 13.557 2.38865 13.9028L2.38864 13.9028L2.39063 13.9053L8.01563 20.9453L8.90625 22.0599V20.6332V14.561C8.90625 13.979 9.38506 13.5 9.96875 13.5C10.5524 13.5 11.0312 13.979 11.0312 14.561V25.0976C11.0312 25.539 10.7453 25.944 10.3193 26.0929L10.3193 26.0929L10.3137 26.0949C10.193 26.1388 10.0788 26.1585 9.96875 26.1585C9.64421 26.1585 9.34324 26.0194 9.14794 25.7626Z" fill="white" stroke="white"/>
              <path d="M16.8281 15.622H16.3281V16.122V23.5366V24.0366H16.8281H22.5625C23.1462 24.0366 23.625 24.5156 23.625 25.0976C23.625 25.6795 23.1462 26.1585 22.5625 26.1585H15.2656C14.6819 26.1585 14.2031 25.6795 14.2031 25.0976V14.561C14.2031 13.979 14.6819 13.5 15.2656 13.5H22.5625C23.1462 13.5 23.625 13.979 23.625 14.561C23.625 15.1429 23.1462 15.622 22.5625 15.622H16.8281Z" fill="white" stroke="white"/>
              <path d="M21.1875 21.3824H16.9375C16.0781 21.3824 15.375 20.68 15.375 19.8215C15.375 18.9629 16.0781 18.2605 16.9375 18.2605H21.1875C22.0469 18.2605 22.75 18.9629 22.75 19.8215C22.75 20.68 22.0469 21.3824 21.1875 21.3824Z" fill="white"/>
              <path d="M29.469 26.6585C28.7815 26.6585 28.1565 26.2059 27.969 25.5034L25.1096 14.9668C24.8909 14.1395 25.3752 13.281 26.2034 13.0468C27.0315 12.8127 27.8909 13.3122 28.1252 14.1395L30.9846 24.6761C31.2034 25.5034 30.719 26.3619 29.8909 26.5961C29.7502 26.6273 29.6096 26.6585 29.4846 26.6585H29.469Z" fill="white"/>
              <path d="M35.5784 26.6585C34.8909 26.6585 34.2659 26.2059 34.0784 25.5034L31.219 14.9668C31.0002 14.1395 31.4846 13.281 32.3127 13.0468C33.1409 12.8127 34.0002 13.3122 34.2346 14.1395L37.094 24.6761C37.3127 25.5034 36.8284 26.3619 36.0002 26.5961C35.8596 26.6273 35.719 26.6585 35.594 26.6585H35.5784Z" fill="white"/>
              <path d="M35.5784 26.6585C35.4378 26.6585 35.3128 26.6429 35.1721 26.5961C34.344 26.3776 33.844 25.519 34.0784 24.6761L36.9378 14.1395C37.1721 13.3122 38.0315 12.8127 38.8596 13.0468C39.6878 13.2654 40.1878 14.1239 39.9534 14.9668L37.094 25.5034C36.9065 26.2059 36.2815 26.6585 35.594 26.6585H35.5784Z" fill="white"/>
              <path d="M29.5784 26.6273C29.4378 26.6273 29.2971 26.6117 29.1721 26.5649C28.344 26.3307 27.844 25.4722 28.0784 24.6449L29.094 20.961C29.3284 20.1337 30.1721 19.6498 31.0159 19.8683C31.844 20.1024 32.344 20.961 32.1096 21.7883L31.094 25.4722C30.9065 26.159 30.2815 26.6273 29.594 26.6273H29.5784Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_10508_10931">
                <rect width="40" height="13.6585" fill="white" transform="translate(0 13)"/>
              </clipPath>
            </defs>
          </svg>
          <div>新着情報</div>
        </a>
      </li>
      <li class="item" :class="active('event')" @click="activeType('event')">
        <a href="javascript:void(0)">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 27L11 4" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 17.6548C15.1209 17.6548 16.9192 21.2704 23.6655 19.5229L26 5.8801C19.2983 7.57943 17.4553 4 11.3345 4" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div>交流ｲﾍﾞﾝﾄ</div>
        </a>
      </li>
      <li class="item" :class="active('share')" @click="activeType('share')">
        <a href="javascript:void(0)">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10508_10915)">
              <path d="M15.1904 1L18.4828 4.73425L13.8755 6.71182" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.86084 16C3.86084 19.3556 5.21779 22.385 7.41626 24.5835" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9998 3.86102C9.28865 3.86102 3.86084 9.29935 3.86084 16" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.81 31.0002L13.5176 27.266L18.1249 25.2884" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M28.1384 16.0001C28.1384 12.6446 26.7815 9.6151 24.583 7.41663" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9995 28.1389C22.7106 28.1389 28.1384 22.7005 28.1384 15.9999" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="16" cy="16" r="5" stroke="#AAAAAA" stroke-width="2"/>
            </g>
            <defs>
              <clipPath id="clip0_10508_10915">
                <rect width="32" height="32" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <div>シェアリング</div>
        </a>
      </li>
      <li class="item" :class="active('partner')" @click="activeType('partner')">
        <a href="javascript:void(0)">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9998 16.8663C17.9532 16.8663 19.5367 15.2827 19.5367 13.3293C19.5367 11.376 17.9532 9.79242 15.9998 9.79242C14.0464 9.79242 12.4629 11.376 12.4629 13.3293C12.4629 15.2827 14.0464 16.8663 15.9998 16.8663Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.9997 20.3638C21.0038 20.3638 24.056 25.3679 24.056 26.56C24.056 27.7521 22.222 28.8 15.9997 28.8C9.77732 28.8 7.94336 27.7652 7.94336 26.56C7.94336 25.3548 10.9825 20.3638 15.9997 20.3638Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.07989 11.3818C9.03327 11.3818 10.6168 9.79829 10.6168 7.8449C10.6168 5.89151 9.03327 4.30798 7.07989 4.30798C5.1265 4.30798 3.54297 5.89151 3.54297 7.8449C3.54297 9.79829 5.1265 11.3818 7.07989 11.3818Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.84371 11.3949C3.81768 11.3949 1.70863 16.7003 1.63003 18.4556C1.53833 20.735 7.07951 20.342 7.07951 20.342" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24.9207 11.3818C26.8741 11.3818 28.4576 9.79829 28.4576 7.8449C28.4576 5.89151 26.8741 4.30798 24.9207 4.30798C22.9673 4.30798 21.3838 5.89151 21.3838 7.8449C21.3838 9.79829 22.9673 11.3818 24.9207 11.3818Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.1567 11.3949C28.1827 11.3949 30.2918 16.7003 30.3704 18.4556C30.4621 20.735 24.9209 20.342 24.9209 20.342" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div>メンバー一覧</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: { },
  props: {
    share: {
      type: Boolean,
      default: false
    },
    isFetchDone: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      type: this.share ? 'share' : 'all'
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    ...mapActions('groups', ['changeGroupCurrentTab']),
    active (type) {
      if (this.type === type) {
        return 'active'
      }
    },
    activeType (type) {
      if (!this.isFetchDone) {
        return
      }
      this.type = type
      this.$emit('type_fetch', type)
      this.changeGroupCurrentTab(type)
    }
  }
}
</script>
<style scoped lang="sass">
@import '~stylesheets/resources'

$mobile-header-z-index: $over-leaflet-z-index
$mobile-sidenav-z-index: $over-leaflet-z-index + 2
$mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
$app-mobile-footer-height: 50px
$primary: #2dba87
$gray: #AAAAAA
$white: #FFF

::-webkit-scrollbar
  display: none

.my-commu-domain
  &.d-none
    display: none
  .clone
    display: none
  &.group-menu-bar
    margin-top: 7px
    color: #273D35
    border-bottom: 4px solid $primary
    ul
      height: 70px
      font-weight: 700
      font-size: 12px
      li
        padding: 10px
        text-align: center
        border-radius: 8px 8px 0 0
        background: $white
        width: 25%
        height: 100%
        overflow-x: auto
        float: left
        a
          font-weight: bold
          font-size: 9px
          line-height: 9px
          height: 100%
          color: #AAAAAA
          cursor: none
        &:first-child
          a svg path
            fill: $gray
            stroke: $gray
        &:first-child.active
          a svg path
            fill: $white !important
      .active
        background: $primary
        a
          color: $white
          svg path, svg circle
            stroke: $white !important
@media only screen and (max-width: 576px)
  .clone
    display: block !important

</style>
